<script>
import GlobalVue from '@helper/Global.vue';
import BaseVue from '@frontend/Base.vue';
import Gen from '@helper/Gen';


export default {
    extends: GlobalVue,
    data() {
        return {
            menu:{},
            menuInfo:{}
        }
    },
    watch: {
        '$route.path'() {
            if(this.isMobile || this.isTablet){
                console.log('a')
                $("#header, #header-wrap").removeClass('h-100')
                $('body').removeClass('primary-menu-open overflow-hidden')
            }
            return;
        },
    },
    methods: {
        getMenu(){
            Gen.apirest("/menu-product", {}, (err, resp) => {
                if (err) console.log(err)
                this.menu = resp.data
            })
        },
        getMenuInfo(){
            Gen.apirest("/menu-info", {}, (err, resp) => {
                if (err) console.log(err)
                this.menuInfo = resp.data
            })
        },
        mobileMenu(){
            $('#primary-menu-trigger,#overlay-menu-close').off( 'click' ).on( 'click', function(){
                $("#header, #header-wrap").toggleClass('h-100')
                $('body').toggleClass('primary-menu-open overflow-hidden')
            })
        }
    },
    async mounted() {
        this.$set(this.$root, 'page', this)
        this.getMenu()
        this.getMenuInfo()
    },
};
</script>

<template>
    <header id="header" :class="$route.name=='Index' ? 'transparent-header static-sticky' : 'transparent-header static-sticky no-slider'" data-sticky-class="not-dark">
        <div id="header-wrap">
            <div class="container clearfix">
                <div id="primary-menu-trigger">
                    <svg class="svg-trigger" viewBox="0 0 100 100" @click="mobileMenu()">
                        <path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"></path>
                        <path d="m 30,50 h 40"></path>
                        <path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"></path>
                    </svg>
                </div>
                <!-- Logo
                        ============================================= -->
                <div id="logo">
                    <router-link :to="{name:'Index'}" class="standard-logo" :data-dark-logo="assets('fo_images','/logo-dark.png')"><img :src="assets('fo_images','/logo.png')"
                            alt="HiLo Logo"></router-link>
                    <router-link :to="{name:'Index'}" class="retina-logo" :data-dark-logo="assets('fo_images','/logo-dark.png')"><img :src="assets('fo_images','/logo.png')"
                            alt="HiLo Logo"></router-link>
                </div><!-- #logo end -->

                <!-- Primary Navigation
                        ============================================= -->
                <nav id="primary-menu" class="style-6 with-arrows">

                    <ul>
                        <li class="search d-none d-lg-block">
                            <a href="#searchBox" data-lightbox="inline"><div><i class="icon-search"></i></div></a>
                        </li>
                        <li :class="$route.name=='Index' ? 'current' : ''">
                            <router-link :to="{name:'Index'}"><div>{{web.menu_home}}</div></router-link>
                        </li>
                        <li :class="$route.name=='About' ? 'current' : ''">
                            <router-link :to="{name:'About'}"><div>{{web.menu_about}}</div></router-link>
                        </li>
                        <li :class="$route.name=='Product' || $route.name=='ProductCat' || $route.name=='ProductDetail' ? 'current' : ''">
                            <router-link :to="{name:'Product'}"><div>Products</div></router-link>
                            <ul>
                                <li><router-link :to="{name:'Product'}"><div>{{web.menu_all_product}}</div></router-link></li>
                                <li v-for="(v,k) in menu" :key="k"><router-link :to="{name:'ProductCat',params:{cat:v.apc_slug}}"><div>{{v.apc_name}}</div></router-link></li>
                            </ul>
                            <i class="icon-angle-right d-lg-none"></i>
                        </li>
                        <li :class="$route.name=='HealthInfo' || $route.name=='HealthInfoCat' || $route.name=='HealthInfoDetail'? 'current' : ''">
                            <router-link :to="{name:'HealthInfo'}"><div>{{web.menu_health_info}}</div></router-link>
                            <ul>
                                <li v-for="(vi,ki) in menuInfo" :key="ki">
                                    <router-link :to="{name:'HealthInfoCat',params:{cat:vi.aic_slug}}"><div>{{vi.aic_name}}</div></router-link>
                                </li>
                            </ul>
                            <i class="icon-angle-right d-lg-none"></i>
                        </li>
                        <li :class="$route.name=='NewsEvent' || $route.name=='NewsEventDetail' ? 'current' : ''">
                            <router-link :to="{name:'NewsEvent'}"><div>{{web.menu_news_event}}</div></a></router-link>
                        </li>
                        <li :class="$route.name=='ContactUs' ? 'current' : ''">
                            <router-link :to="{name:'ContactUs'}"><div>{{web.menu_contact}}</div></router-link>
                        </li>
                    </ul>
                    

                </nav><!-- #primary-menu end -->
                <div id="primary-search"><a href="#searchBox" data-lightbox="inline"><i class="icon-search"></i></a></div>
            </div>

        </div>
    </header>
</template>